import { initializeApp } from 'firebase/app';
// import { getMessaging } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Stagin Config
const StagingConfig = {
	apiKey: "AIzaSyCII_kqlxl_PAcbfAbBLFsWBP9dfvLLe3M",
	authDomain: "lenmo-2a623.firebaseapp.com",
	databaseURL: "https://lenmo-2a623.firebaseio.com",
	projectId: "lenmo-2a623",
	storageBucket: "lenmo-2a623.appspot.com",
	messagingSenderId: "478466344575",
	appId: "1:478466344575:web:b82ce9761adb7c730fe14a",
	measurementId: "G-E8YN7YEKT7",
	webClientId: "478466344575-jl1lugcoubstdf5li74vsv5egqjupf35.apps.googleusercontent.com",

};

// Production Config
const productionConfig = {
	apiKey: "AIzaSyCII_kqlxl_PAcbfAbBLFsWBP9dfvLLe3M",
	authDomain: "lenmo-2a623.firebaseapp.com",
	databaseURL: "https://lenmo-2a623.firebaseio.com",
	projectId: "lenmo-2a623",
	storageBucket: "lenmo-2a623.appspot.com",
	messagingSenderId: "478466344575",
	appId: "1:478466344575:web:e990d308dc29008a0fe14a",
	measurementId: "G-3MZPZ0B0C7",
};

// Init
const { REACT_APP_ENVIRONMENT } = process.env;
console.log('REACT_APP_ENVIRONMENT ', REACT_APP_ENVIRONMENT)
const app = initializeApp(StagingConfig);

export default {
	// messaging: getMessaging(app),
	analytics: getAnalytics(app),
	performance: getPerformance(app),
	storage: getStorage(app),
	database: getDatabase(app),
	auth: getAuth(app),
	googleAuthProvider: new GoogleAuthProvider(),
};
