import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
// import PropTypes from "prop-types";
import { Formik } from "formik";
import signupService from "../../services/signupService";
import * as Yup from "yup";
// Import Services
import auth2 from "../../services/authService";
// Import Components
import LNFloatingInput from "../common/LNFloatingInput/LNFloatingInput";
import LNError from "../common/LNError/LNError";
import CenterImageSVG from "../../img/static/logo.svg"
// Import Images
import Logo from "../../img/assets/logo.svg";

// Import CSS
import "./loginPage.css";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';


class LoginPage extends Component {
	
	state = {
		loading: true,
		data: {
			email: "",
			password: "",
		},
		error: false,
		errorMessage: "",
		pressCount: 0,
	};
	

	onFormikSubmit = async (values, { setSubmitting, setErrors }) => {
		const self = this;
		const auth = getAuth();
		this.setState({
			error:false,
		})
		setSubmitting(true)
		await signInWithEmailAndPassword(auth, values?.email , values?.password).then(async (response)=>{
			console.log("response from firebase", response);
			if(response?.user?.accessToken){
				try {
					this.populateTheCurrentStep()
				} catch (error) {
					console.log("error in populateTheCurrentStep",error);
				}
			}
			// await auth2.socialLogin(response?.user?.accessToken).then(()=>{
			// 	this.populateTheCurrentStep()
			// }).catch((error) => {
			// 	console.error("socialLogin_Error",error);
			// })
		  })
		//   setSubmitting(false)
		.catch((error)=>{
			const errorCode = error.code;
			console.log("errorCode",errorCode);
			if(errorCode === "auth/invalid-credential"||errorCode.toString().includes("invalid-credential")){
				this.setState({
					error:true,
					errorMessage: "Sorry, we couldn't find an account with that email or password. Please try again"
				})
			}
			if(errorCode == "auth/too-many-requests"){
				this.setState({
					error:true,
					errorMessage: "You have exceeded the maximum number of login attempts. Please wait and try again later. or reset your password."
				})
			}
			if(errorCode == "auth/user-disabled"){
				this.setState({
					error:true,
					errorMessage: "This account has been deactivated."
				})
			}
			else{
				this.setState({
					error:true,
					errorMessage:error.message
				})
			}
			setSubmitting(false)
		})
	}
	

	handleGoogleSignIn = async () => {
		const auth = getAuth();
		const provider = new GoogleAuthProvider();
		provider.addScope('profile');
		provider.addScope('email');
		provider.addScope('openid');
		await signInWithPopup(auth, provider).then(async(result)=>{
			const user = result.user;
			const credential = GoogleAuthProvider.credentialFromResult(result);
			const token = credential.idToken;
			console.log("token after update",token);
			// await auth2.socialLogin(user?.accessToken).then((res)=>{
				this.populateTheCurrentStep()
			}).catch((err) => {
				console.error("error",err);
		}).catch((error)=>{
			console.log("Error_In_signInWithPopup_Login", error);
			window.location.reload()
		})		
	}
	// Get Account
	populateAccount() {
		const account = auth2.getCurrentAccount();
		this.setState({ account });
	}

	// Handle Loading
	handleLoading() {
		const loading = false;
		this.setState({ loading });
	}

	
 	loadCount = async () => {
		try {
		  const storedCount = await localStorage.getItem('pressCount');
		  if (storedCount) {
			this.setState({ pressCount: parseInt(storedCount, 10) });
			setTimeout(() => {
				console.log("parseInt(storedCount)",this.state.pressCount);
			}, 1000);
		  }
		} catch (error) {
		  console.error('Error loading press count:', error);
		}
	}

	async populateTheCurrentStep() {
		try {
			const { data } = await signupService.getRegistrationStep();
			console.log("this.state.pressCount", this.state.pressCount);
			console.log("datapopulateTheCurrentStep", data?.registration_step);
			if(data?.registration_step <= 6 && this.state.pressCount <= 3 ){
				setTimeout(() => {
					window.location = "/signup-process";
				}, 1000)
			}else{
				setTimeout(() => {
					window.location = "/";
				}, 1000)
			}
			// this.setState({ currentStep: data.registration_step });
		} catch (ex) {
			console.log('populateTheCurrentStep_login_ex ', ex)
			setTimeout(() => {
				if(this.state.pressCount >= 3){
					window.location = "/";
				}else{
					window.location = "/signup-process";
				}
			}, 1000);
		}
	}


	// When Mount
	componentDidMount() {
		// Get count
		this.loadCount();
		// Get account
		this.populateAccount();

		// End loading
		this.handleLoading();
	}

	// Render
	render() {
		const centerImage = require('../../img/static/logo.svg');
		const googleIcon = require('../../img/static/Google.png');
		const { loading, account, data, error, errorMessage } = this.state;
	
		if (account) return <Navigate to="/" />;

		// Validations
		const ValidationSchema = Yup.object().shape({
			email: Yup.string()
				.email("Please enter a valid email address.")
				.max(255, "Too Long!")
				.required("Required field"),
			password: Yup.string().min(1, "Too Short!").max(255, "Too Long!").required("Required field"),
		});

		// Check if loading
		if (loading) return <div>Loading...</div>;

		// Render
		return (
			<div className="bg-landing" style={{
				display: 'flex',
				flex:  1,
				backgroundColor: '#F8F9FF',
				flexDirection:"row",
				alignItems:"center",
				justifyContent:"center"
			  }}>
				<div className="bg-landing" style={{
					display: 'flex',
					flex: 0.45,
					alignItems:"center",
					justifyContent:"center",
					backgroundColor: 'white',
			  	}}>
				<div style={{
					maxWidth:388,
					display: 'flex',
					flex: 1,
					backgroundColor: 'white',
					justifyContent:"center",
					flexDirection:"column",
					margin:20
				}}>
					<div style={{marginTop:30}} className="mb-3 logo">
						<img style={{width:36, height:36}} src={Logo} alt="Logo" />
					</div>
					<div style={{
						color:"#0C1421",
						fontSize:32,
						marginTop:14 ,
						fontWeight:"600"
					}}>
						Welcome back to Lenme Enterprise
					</div>
					<div style={{
						color:"#313957",
						fontSize:16,
						marginTop:24,
						marginBottom:30
						}}>
						Today is a new day. It's your day. You shape it. 
						Sign in to start managing your projects.
					</div>
					{error && <LNError text={errorMessage} />}
					<Formik
						initialValues={data}
						validationSchema={ValidationSchema}
						onSubmit={this.onFormikSubmit}
					>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
					}) => (
					<form onSubmit={handleSubmit}>
						<div style={{
							marginBottom:8,
							fontSize:16,
							color:"#0C1421",
						}}>
							Email
						</div>
						<LNFloatingInput
							style={{height:48}}
							size="lg"
							type="email"
							optional={false}
							name="email"
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder="Example@email.com"
							error={touched.email && errors.email ? `* ${errors.email}` : ""}
						/>
						<div style={{
							marginTop:24,
							marginBottom:8,
							fontSize:16,
							color:"#0C1421"
						}}>
							Password
						</div>
						<LNFloatingInput
							style={{height:48, marginBottom:20}}
							size="lg"
							type="password"
							optional={false}
							name="password"
							value={values.password}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder="At least 8 characters"
							error={
								touched.password && errors.password
									? `* ${errors.password}`
									: ""
							}
						/>
						<div style={{marginBottom:21}} >
							<Link to="/forgot-password" style={{
								color:"#1E4AE9",
								fontSize:16
							}}>
								Forgot password?
							</Link>
						</div>
						<button style={{
							color:"#FFFFFF",
							fontSize:16,
							fontWeight:"100",
							borderRadius:12,
							}} 
							className="btn btn-primary btn-lg btn-block mt-4"
							type="submit"
							disabled={isSubmitting}
							onClick={handleSubmit}>
							Sign in
						</button>
					</form>
				)}
				</Formik>
				<div style={{
					display:"flex",
					alignItems:"center",
					alignSelf:"center",
					marginTop:5,
					justifyContent:"center",
					marginBottom:12
					}}>
					<div style={{
						width:168.5,
						backgroundColor:"#CFDFE2",
						height:1,
						borderRadius:10
					}}/>			
						<p className="mt-4" style={{marginLeft:20,marginRight:20}}>Or</p>
					<div style={{
						width:168.5,
						backgroundColor:"#CFDFE2",
						height:1,
						borderRadius:10
					}}/>
				</div>
				<div className="btn btn-black btn-lg" style={{
					color:"#FFFFFF",
					fontSize:16,
					fontWeight:"100",
					borderRadius:12,
					marginBottom:24
				}} 
				onClick={this.handleGoogleSignIn}>
					<img style={{alignSelf:"center",height:20,marginRight:10}} src={googleIcon} alt="Logo" />		
					Sign in with Google
				</div>
				<div>
					<div style={{
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "center",
							marginTop:21
						}}>
						<p style={{
							color:"#313957",
							fontSize:16,
						}}>
							Don't you have an account?
						</p>
						<Link to="/signup" 
							style={{
							color:"#1E4AE9",
							fontSize:16,
							marginLeft:5
						}}>
							Sign up
						</Link>
					</div>	
				</div>
			</div>
		</div>
		<div style={{flex:0.55,justifyContent:"center", display:"flex"}}>

		 <div className="col-lg-6 d-none d-sm-flex" style={{justifyContent:"center",alignItems:"center"}}>
				<img src={CenterImageSVG} alt="Logo" />
			</div> 
		</div>
	</div>
			// <div className="bg-landing">
			// 	<div>
			// 		<div className="row max-vh-100 justify-content-center align-items-center">
			// 			<div className="col-12 col-md-10 col-lg-6 col-xl-5">
			// 				<div className="pre-login-form bg-white  justify-content-center align-items-center">
			// 					<div className="mb-3 logo">
			// 						<img src={Logo} alt="Logo" />
			// 					</div>
			// 					<div style={{
			// 						color:"#0C1421",
			// 						fontSize:32,
			// 						marginTop:20,
			// 						marginBottom:20,
			// 						fontWeight:"600"
			// 					}}>
			// 						Welcome back to Lenme Enterprise
			// 					</div>
			// 					<div style={{
			// 						color:"#313957",
			// 						fontSize:18,
			// 						marginTop:20,
			// 						marginBottom:20
			// 						}}>
			// 						Today is a new day. It's your day. You shape it. 
			// 						Sign in to start managing your projects.
			// 					</div>

			// 					{error && <LNError text={errorMessage} />}

			// 					<div className="mb-2">
			// 						<Formik
			// 							initialValues={data}
			// 							validationSchema={ValidationSchema}
			// 							onSubmit={this.onFormikSubmit}
			// 							>
			// 							{({
			// 								values,
			// 								errors,
			// 								touched,
			// 								handleChange,
			// 								handleBlur,
			// 								handleSubmit,
			// 								isSubmitting,
			// 							}) => (
			// 								<form onSubmit={handleSubmit}>
			// 									<div style={{
			// 										marginTop:10,
			// 										marginBottom:10,
			// 										fontSize:16,
			// 										color:"#0C1421",
			// 									}}>
			// 										Email
			// 									</div>

			// 									<LNFloatingInput
			// 										size="lg"
			// 										type="email"
			// 										optional={false}
			// 										name="email"
			// 										value={values.email}
			// 										onChange={handleChange}
			// 										onBlur={handleBlur}
			// 										placeholder="Example@email.com"
			// 										error={touched.email && errors.email ? `* ${errors.email}` : ""}
			// 									/>
			// 									<div style={{
			// 										marginTop:10,
			// 										marginBottom:10,
			// 										fontSize:16,
			// 										color:"#0C1421"
			// 									}}>
			// 										Password
			// 									</div>
			// 									<LNFloatingInput
			// 										size="lg"
			// 										type="password"
			// 										optional={false}
			// 										name="password"
			// 										value={values.password}
			// 										onChange={handleChange}
			// 										onBlur={handleBlur}
			// 										placeholder="At least 8 characters"
			// 										error={
			// 											touched.password && errors.password
			// 												? `* ${errors.password}`
			// 												: ""
			// 										}
			// 									/>
			// 									<div className="mb-4 mt-1">
			// 										<Link to="/forgot-password" style={{
			// 											color:"#1E4AE9",
			// 											fontSize:16
			// 										}}>
			// 											Forgot password?
			// 										</Link>
			// 									</div>

			// 									<button style={{
			// 										color:"#FFFFFF",
			// 										fontSize:16,
			// 										fontWeight:"100",
			// 										borderRadius:12
			// 										}} 
			// 										className="btn btn-primary btn-lg btn-block mt-4"
			// 										type="submit"
			// 										disabled={isSubmitting}
			// 										onClick={handleSubmit}>
			// 										Sign in
			// 									</button>
			// 								</form>
			// 							)}
			// 						</Formik>
			// 						<div style={{
			// 							display:"flex",
			// 							alignItems:"center",
			// 							alignSelf:"center",
			// 							marginTop:10,
			// 							justifyContent:"center"
			// 						}}>
			// 						<div style={{
			// 							width:"45%",
			// 							backgroundColor:"#CFDFE2",
			// 							height:1,
			// 							borderRadius:10
			// 						}}/>
								
			// 						<p style={{marginLeft:10,marginRight:10}} className="mt-4">Or</p>
			// 						<div style={{
			// 							width:"45%",
			// 							backgroundColor:"#CFDFE2",
			// 							height:1,
			// 							borderRadius:10
			// 						}}/>
			// 						</div>
			// 						<div
			// 							style={{
			// 								color:"#FFFFFF",
			// 								fontSize:16,
			// 								fontWeight:"100",
			// 								borderRadius:12,
			// 							}} 
										
			// 							className="btn btn-black btn-lg btn-block mt-4" 
			// 							onClick={this.handleGoogleSignIn}>
			// 								<img style={{alignSelf:"center",height:20,marginRight:10}} src={googleIcon} alt="Logo" />		
			// 								Sign in with Google
			// 							</div>
			// 					</div>
			// 					<div>
			// 						<div style={{
			// 							  display: "flex",
			// 							  alignItems: "flex-start",
			// 							  justifyContent: "center",
			// 							  marginTop:40
			// 							}}>
			// 							<p style={{
			// 								color:"#313957",
			// 								fontSize:18,
			// 							}}>
			// 								Don't you have an account?
			// 							</p>
			// 							<Link to="/signup" 
			// 								style={{
			// 									color:"#1E4AE9",
			// 									fontSize:18,
			// 									marginLeft:5
			// 								}}>
			// 								Sign up
			// 							</Link>
			// 						</div>
			// 					</div>
			// 				</div>
			// 			</div>
						
			// 			<div className="col-lg-6 d-none d-sm-flex" style={{justifyContent:"center",alignItems:"center"}}>
			// 				<img src={centerImage} alt="Logo" />		
			// 			</div>

			// 		</div>
			// 	</div>
			// </div>
		);
	}
}

export default LoginPage;
